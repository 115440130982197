/* eslint-disable react/no-array-index-key */
import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "./components/background-image"
import { SectionType, CardType } from "../../types"
import { createMarkup } from "../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

type CardProps = {
  card: CardType
}

const LinkCard = ({ card }: CardProps) => {
  return (
    <Link className="flex flex-col mx-2 w-full lg:w-9/30 mt-4" activeClassName="active" to={card.link}>
      <div
        className={`p-8 ${card.class || `p-8 border-solid border-2 border-primary rounded bg-background`}`}
        style={{
          minHeight: card.minHeight || 300,
        }}
      >
        {card.title && <div className="flex-none w-100" style={{ minHeight: `80px` }} dangerouslySetInnerHTML={createMarkup(card.title)} />}
        {card.blurb && <div className="flex-grow w-100 py-2 " dangerouslySetInnerHTML={createMarkup(card.blurb)} />}
        {card.footer && <div className="flex-none w-100 mt-2 " dangerouslySetInnerHTML={createMarkup(card.footer)} />}
      </div>
    </Link>
  )
}

const NoLinkCard = ({ card }: CardProps) => {
  return (
    <div
      className={`flex flex-col w-full lg:w-9/30 mt-4 mx-2 ${card.class || `p-8 border-solid border-2 border-primary rounded bg-background`}`}
      style={{
        minHeight: card.minHeight || 300,
      }}
    >
      {card.title && <div className="flex-none w-100" style={{ minHeight: `80px` }} dangerouslySetInnerHTML={createMarkup(card.title)} />}
      {card.blurb && <div className="flex-grow w-100 py-2 " dangerouslySetInnerHTML={createMarkup(card.blurb)} />}
      {card.footer && <div className="flex-none w-100 mt-2 " dangerouslySetInnerHTML={createMarkup(card.footer)} />}
    </div>
  )
}

const CardsSection = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">
        {section.blurb && <div className="pb-6" dangerouslySetInnerHTML={createMarkup(section.blurb)} />}

        <div className="flex flex-wrap justify-center py-0 md:py-2">
          {section.cards &&
            section.cards.map((card: CardType, cardIndex: number) => (card.link ? <LinkCard card={card} key={`card-${cardIndex}`} /> : <NoLinkCard card={card} key={`card-${cardIndex}`} />))}
        </div>
      </div>
    </div>
  )
}

export default CardsSection
